import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appImageBreakPoint]',
  exportAs: 'appImageBreakPoint',
})
export class ImageBreakPointDirective implements OnInit, AfterViewInit {
  selectedPoint: number;
  source: string;
  isInit: boolean = false;
  @HostBinding('src') src: string;

  @Input() default = 300;
  @Input('appImageBreakPoint') set _source(src: string) {
    this.source = src;

    if (this.isInit) {
      this.ngAfterViewInit();
    }

  }
  @Input() breakPoints: number[] = [
    200, 250,
    800, 850, 900, 950, 1000, 1050, 1100, 1150, 1200
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private eleRef: ElementRef
  ) { }

  // @HostListener('error', ['$event.target'])
  // onError(imgElement) {
  //   this.src = 'this._path';
  // }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.isInit = true;
    const width = this.eleRef.nativeElement.getBoundingClientRect().width;
    const index = this.breakPoints.findIndex((v) => width <= v);
    const max = this.breakPoints[this.breakPoints.length - 1];
    const min = this.breakPoints[0];

    if (index === -1 && width <= min) {
      this.selectedPoint = min;
    } else if (index === -1 && width >= max) {
      this.selectedPoint = max;
    } else {
      this.selectedPoint = this.breakPoints[index];
    }
    setTimeout(
      () => (this.src = this.source + '?width=' + this.selectedPoint),
      0
    );
  }
}
